import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'contextDropdown', 
    'liquidTextField', 
    'customerLiquid', 
    'reservationLiquid', 
    'giftVoucherLiquid',
    'giftVoucherReceiptLiquid'
  ]

  connect() {
    this.element.addEventListener('trix-initialize', this.handleTrixInitialize.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('trix-initialize', this.handleTrixInitialize.bind(this));
  }

  handleTrixInitialize(event){
    this.initializeVariables()
  
    const trixToolbar = document.getElementById('trix-toolbar-1');
    this.addButtonGroupsToTrixToolbar(this.usedToolset, trixToolbar)
  }
  
  addButtonGroupsToTrixToolbar(usedToolSet, trixToolbar) {
    const removableElements = document.querySelectorAll('.removable');
    removableElements.forEach(element => element.remove());
    
    Object.keys(usedToolSet).forEach(tool => {
      const humanizedLabel = this.humanize(tool);
      
      trixToolbar.insertAdjacentHTML('beforeend', `<div class='trix-button-row trix-innstyle ${tool}-markdown-tools removable'><span class='trix-button-group trix-button-group--${tool}-markdown-tools' data-trix-button-group='${tool}-markdown-tools'></span><label>${humanizedLabel}</label></div>`)
      usedToolSet[tool].forEach(({ action, name }) => {
        const toolGroup = document.querySelector(`[data-trix-button-group='${tool}-markdown-tools']`);
        const stimulusAction = "data-action='click->admin--trix-string-insert#insertLiquid'"

        toolGroup.insertAdjacentHTML('beforeend', `<button class='trix-button string-insert ${tool}' type='button' ${stimulusAction} data-trix-attribute='${CSS.escape(action)}'><${tool}>${name}</${tool}></button>`)
      });
    });
  }

  initializeVariables(){
    this.tools = {
      customer: [
        { action: '{{customer.title}}', name: 'honorific title' },
        { action: '{{customer.first_name}}', name: 'first name' },
        { action: '{{customer.last_name}}', name: 'last name' },
        { action: '{{customer.email}}', name: 'email' }
      ],
      reservation: [
        { action: '{{reservation.arrival_date}}', name: 'arrival date' },
        { action: '{{reservation.departure_date}}', name: 'departure date' },
        { action: '{{reservation.id}}', name: 'reservation id' },
        { action: '{{bookings_summary}}', name: 'booking summary' },
        { action: '{{bill.outstanding_balance}}', name: 'outstanding balance' },
        { action: '{{bill_summary}}', name: 'bill summary' },
        { action: '{{reservation_statement}}', name: 'statement' },
        { action: '{{manage_reservation_link}}', name: 'manage reservation link' },
        { action: '{{booking_protection_link}}', name: 'booking protection link' }
      ],
      property: [
        { action: '{{header}}', name: 'header' },
        { action: '{{reservation.property_name}}', name: 'property name' },
        { action: '{{property_address}}', name: 'property address' },
        { action: '{{policies}}', name: 'policies' },
        { action: '{{hr}}', name: 'horizontal line' }
      ],
      gift_voucher: [
        { action: '{{gift_voucher.recipient_name}}', name: 'recipient name' },
        { action: '{{gift_voucher.recipient_email}}', name: 'recipient email' },
        { action: '{{gift_voucher.redemption_code}}', name: 'redemption code' },
        { action: '{{gift_voucher.expiry_date}}', name: 'expiry date' },
        { action: '{{gift_voucher.purchased_by}}', name: 'purchased by' },
        { action: '{{gift_voucher.custom_message}}', name: 'custom message' }
      ],
      gift_voucher_receipt: [
        { action: '{{gift_voucher.recipient_name}}', name: 'recipient name' },
        { action: '{{gift_voucher.recipient_email}}', name: 'recipient email' },
        { action: '{{gift_voucher.expiry_date}}', name: 'expiry date' },
        { action: '{{gift_voucher.purchased_by}}', name: 'purchased by' },
        { action: '{{gift_voucher.custom_message}}', name: 'custom message' }
      ]
    };
    
    this.contextArray = this.contextDropdownTarget.selectedOptions[0].dataset.trixSections.split(' ');
    this.usedToolset = this.usedToolsInContext(this.tools, this.contextArray)
  }
  
  usedToolsInContext(tools, contextArray){
    const usedTools = {};
    this.giftVoucherLiquidTarget.style.display = 'none';
    this.customerLiquidTarget.style.display = 'none';
    this.reservationLiquidTarget.style.display = 'none';
    
    contextArray.forEach(context => {
      switch (context) {
        case 'reservation':
          usedTools.reservation = this.tools.reservation
          this.reservationLiquidTarget.style.display = 'block';
          break;
        case 'customer':
          usedTools.customer = this.tools.customer
          this.customerLiquidTarget.style.display = 'block';
          break;
        case 'property':
          usedTools.property = this.tools.property;
          break;
        case 'gift_voucher_receipt':
          usedTools.gift_voucher_receipt = this.tools.gift_voucher_receipt;
          console.log(usedTools.gift_voucher_receipt)
          this.giftVoucherLiquidTarget.style.display = 'block';
          this.giftVoucherReceiptLiquidTarget.style.display = 'none';
          break;
        case 'gift_voucher':
          usedTools.gift_voucher = this.tools.gift_voucher;
          this.giftVoucherLiquidTarget.style.display = 'block';
          this.giftVoucherReceiptLiquidTarget.style.display = 'block';
          break;
      }
    });
    
    return usedTools;
  }
  
  humanize(str) {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  
  contextDropdown(event) {
    const contexts = event.currentTarget.selectedOptions[0].dataset.trixSections.split(' ');
    const usedTools = {};
    contexts.forEach(context => {
      switch (context) {
        case 'reservation':
          usedTools.reservation = this.tools.reservation
          break
        case 'customer':
          usedTools.customer = this.tools.customer
          break;
        case 'property':
          usedTools.property = this.tools.property;
          break;
        case 'gift_voucher':
          usedTools.gift_voucher = this.tools.gift_voucher;
          break;
      }
    });
    
    return usedTools;
  }
  
  dropdownChange(event){
    this.handleTrixInitialize()
  }
  
  insertLiquid(event){
    const liquid = event.currentTarget.dataset.trixAttribute.replace(/\\/g, '');
    const textField = this.liquidTextFieldTarget
    
    textField.value += `${liquid}`
  }
}


