import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["info"];

  connect() {
    const selectEl = this.element.querySelector("#rates_update_season_id")
    this.fetchInfo(selectEl.value)
  }

  handleChange(event) {
    this.fetchInfo(event.currentTarget.value)
  }

  async fetchInfo(seasonId) {
    let url = this.element.dataset.url.replace("id", seasonId);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        },
      })
      const html = await response.text()
      console.log(html)
      this.infoTarget.innerHTML = html;
    } catch (error) {
      console.error("Error fetching season dates info")
    }
  }
}


