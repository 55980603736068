import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "hiddenInput"];
  static values = { submitted: Boolean };

  connect() {
    this.submittedValue = false;

    this.inputTargets.forEach((input, index) => {
      input.addEventListener("input", () => {
        if (input.value.length > 1) {
          this.handleAutofill(input.value);
        } else if (input.value.length === 1) {
          this.focusNextInput(index);
        }
      });

      input.addEventListener("keydown", (event) => {
        if (event.key === "Backspace" && input.value.length === 0) {
          this.focusPreviousInput(index);
        }
      });
    });
  }

  handleAutofill(otp) {
    const otpValues = otp.slice(0, this.inputTargets.length).split("");

    otpValues.forEach((value, i) => {
      this.inputTargets[i].value = value;
    });

    this.trySubmitForm();
  }

  focusNextInput(currentIndex) {
    const nextInput = this.inputTargets[currentIndex + 1];
    if (nextInput) {
      nextInput.focus();
    } else {
      this.trySubmitForm();
    }
  }

  focusPreviousInput(currentIndex) {
    const prevInput = this.inputTargets[currentIndex - 1];
    if (prevInput) {
      prevInput.focus();
    }
  }

  gatherCode() {
    const otpCode = this.inputTargets.map((input) => input.value).join("");
    this.hiddenInputTarget.value = otpCode;
  }

  trySubmitForm() {
    if (!this.submittedValue) {
      this.submittedValue = true;
      this.submitForm();
    }
  }

  submitForm() {
    this.gatherCode();
    this.element.submit();
  }
}
